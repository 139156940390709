<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-11-01 11:43:41
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-02 09:47:16
-->
<template>
<div>
    <a-form-model :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules " ref="formRef">
      <a-form-item label="系统" name="">
          <a-input :value="formState.appName" disabled></a-input>
        </a-form-item>
        <a-form-item label="上级菜单" name="parentId">
          <a-tree-select
              v-model="formState.parentId"
              show-search
              :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="为空=1级菜单"
              allow-clear
              :tree-data="menuList"
          >
            <span v-if="key === '0-0-1'" slot="name" slot-scope="{ key, value }" style="color: #08c">
              Child Node1 {{ value }}
            </span>
          </a-tree-select>
        </a-form-item>
        <a-form-item label="菜单名称" name="name">
          <a-input v-model="formState.name" placeholder="请输入"/>
        </a-form-item>
        <a-form-item label="链接" name="path">
          <a-input v-model="formState.path" placeholder="链接url地址，页面相对路径"/>
        </a-form-item>
        <a-form-item label="排序" name="sort">
          <a-input v-model="formState.sort" placeholder="排序号：序号越小越靠前"/>
        </a-form-item>
        <a-form-item label="介绍描述" name="memo">
          <a-textarea v-model="formState.memo" placeholder="请输入你的介绍描述">
          </a-textarea>
        </a-form-item>

        <a-form-item label="应用封面图">

        </a-form-item>
        <a-form-item label="菜单类型" name="menuType">
          <a-radio-group v-model="formState.menuType">
            <a-radio value="1">菜单</a-radio>
            <a-radio value="2">按钮</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="可见" name="visible">
          <div>
            <a-radio-group v-model="formState.visible">
              <a-radio :value=1>显示</a-radio>
              <a-radio :value=0>隐藏</a-radio>
            </a-radio-group>
          </div>
        </a-form-item>
        <a-form-item label="权限标识" name="permission">
          <a-input v-model="formState.permission" placeholder="请输入"/>
        </a-form-item>
        <!-- <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
          <a-button style="margin-left: 20px" @click="closeModel">取消</a-button>
          <a-button style="margin-left: 50px" type="primary" @click="saveOrUpdateAppMenuFun" :disabled="isDisable">确定</a-button>
        </a-form-item> -->
        <!-- 底部按钮 -->
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
            <a-button type="primary" @click.prevent="onSubmit">提交</a-button>
            <a-button style="margin-left: 10px" @click="resetFields">取消</a-button>
        </a-form-item>
        <br/>
        <br/>
        <br/>
    </a-form-model>
</div>
</template>

<script>
export default {
  data () {
    return {
      iseditorAdd: '',
      isDisable: true,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 16
      },
      formState: {
        id: undefined,
        appId: undefined,
        name: '',
        parentId: '',
        path: '',
        icon: '',
        menuType: '',
        visible: '',
        memo: '',
        sort: undefined,
        permission: '',
        appName: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: '给目标起个名字',
            trigger: 'change'
          }],
        menuType: [
          {
            required: true,
            message: '请完善信息',
            trigger: 'change'
          }
        ],
        visible: [
          {
            required: true,
            message: '请完善信息',
            trigger: 'change'
          }
        ]
      },
      menuList: []
    }
  },
  methods: {
    onSubmit () {
      // this.submitForm()
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async submitForm () {
      let apiStr = ''
      apiStr = this.iseditorAdd === 'add' ? 'saveAppMenu' : this.iseditorAdd === 'edit' ? 'updateAppMenu' : ''
      console.log(apiStr, 'apiStr')
      const res = await this.$store.dispatch('http', {
        api: apiStr,
        params: { ...this.formState }
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
        this.$emit('ok')
      }
    },
    async init ({ editorAdd, id, parentId, appObj }) {
      console.log(parentId, 'ppppaaarentid')
      // 清空form
      // 赋值 appName appId
      // 回显数据
      this.resetFormState()
      this.iseditorAdd = editorAdd
      this.getMenuTree(appObj)
      if (editorAdd === 'add') {
        this.setAppInfo(appObj)
        this.formState.parentId = id
        return
      }
      const res = await this.$store.dispatch('http', {
        api: 'queryAppMenuInfo',
        query: { id: id }
      })
      this.formState = { ...res }
      this.setAppInfo(appObj)
    },
    setAppInfo (appObj) {
      this.formState.appName = appObj.name
      this.formState.appId = appObj.id
    },
    // formState清空
    resetFormState () {
      this.formState = {
        id: undefined,
        appId: undefined,
        name: '',
        parentId: '',
        path: '',
        icon: '',
        menuType: '1',
        visible: 1,
        memo: '',
        sort: undefined,
        permission: '',
        appName: ''
      }
    },
    async getMenuTree (appObj) {
      const res2 = await this.$store.dispatch('http', {
        api: 'queryMenuTreeList',
        params: { appId: appObj.id }
      })
      console.log(res2, 'menulist')
      this.menuList = res2
    },
    resetFields () {
      console.log('resetFields')
      this.$refs.formRef.resetFields()
      this.$emit('ok')
    },
    saveOrUpdateAppMenuFun () {
      console.log(11)
    }
  }
}
</script>

<style>

</style>
