<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-15 10:51:45
-->
<template>
  <div id="page-list">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button class="add-bt" type="primary" @click="(()=>{showModal({editorAdd:'add',id:'',parentId:'0'})})">新增</a-button>
    </Breadcrumb>
    <!-- 查询 -->
    <!-- {{formState}}---{{appObj}} -->
    <a-form-model
      ref="formRef"
      layout="inline"
      :model="formState"
    >
      <a-form-model-item label="应用">
        <a-select v-model="formState.appId" placeholder="请选择" style="width:180px;" @change="changeApp">
          <a-select-option v-for="(item) in appSelectData" :key="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- <a-form-item>
        <a-select
          :allowClear="true"
          v-model="formState.code"
          :options="codeList"
          placeholder="页面类型"
          style="width: 180px"
        >
        </a-select>
      </a-form-item> -->

      <a-form-model-item>
        <a-button @click="((e)=>{queryData()})" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <!-- <a-button @click="resetForm">重置</a-button> -->
      </a-form-model-item>
    </a-form-model>
    <br />
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :rowKey="(record) => record.id"
    >
      <div slot="action" slot-scope="record">
        <span>
          <a @click="(()=>{showModal({ editorAdd:'add',  id:record.id, parentId:record.parentId })})">新增</a>
          <a-divider type="vertical" />
          <a @click="(()=>{showModal({ editorAdd:'edit', id:record.id, parentId:record.parentId })})">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除此条信息吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(record.id)"
            @cancel="cancel"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </span>
      </div>
    </a-table>
    <br />
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
    <!-- 弹窗口 -->
    <a-modal v-model="visible" title="新增/编辑" @ok="handleOk" :footer="null">
      <AppMenu ref="refModal"  @ok="handleOk"></AppMenu>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import AppMenu from './components/addEditAppMenu'
export default {
  components: {
    Breadcrumb,
    AppMenu
  },
  data () {
    return {
      appObj: {
        id: '',
        name: ''
      },
      breadList: [
        {
          name: '页面设计',
          url: '/allocation/list'
        },
        {
          name: '页面列表',
          url: '/allocation/list'
        }
      ],
      appSelectData: [],
      columns: [
        { title: '菜单名称', dataIndex: 'name', key: 'name', width: '300' },
        { title: '排序', dataIndex: 'sort', key: 'sort', width: '100' },
        { title: '链接', dataIndex: 'path', key: 'path' },
        { title: '类型', dataIndex: 'menuType', key: 'menuType' },
        { title: '可见', dataIndex: 'visible', key: 'visible' },
        { title: '权限标识', dataIndex: 'permission', key: 'permission' },
        { title: '操作', scopedSlots: { customRender: 'action' }, key: 'operation' }
      ],
      visible: false,
      formState: {
        appId: ''
      },
      data: [],
      total: 0,
      pageSize: 10,
      current: 1
    }
  },
  async created () {
    // const fn = this.queryData()
    const res = await this.queryApps()
    if (res) {
      this.formState.appId = res[0].id
      const { id, name } = { ...res[0] }
      console.log(id, name, 'id,name')
      this.appObj.id = id
      this.appObj.name = name
      this.queryData()
    }
  },
  methods: {
    changeApp (e) {
      console.log(e)
      this.appSelectData.forEach((item, index) => {
        if (item.id === e) {
          const { id, name } = { ...item }
          console.log(id, name)
          this.appObj.id = id
          this.appObj.name = name
        }
      })
      this.queryData()
    },
    async queryApps () {
      const res = await this.$store.dispatch('http', {
        api: 'queryApps'
      })
      this.appSelectData = res
      return res
    },
    // 下架商品
    async soldUpDown (record) {
      const res = await this.$store.dispatch('http', {
        api: 'getManagePageIssue',
        query: {
          pageId: record.id
        }
      })
      console.log(res)
      if (res) {
        // this.queryData(10) // 传入数字 重置列表不会更改当前页
        this.queryData() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    },
    // 删除页面
    async deleteMenu (id) {
      console.log('deleteMenu', id)
      const res = await this.$store.dispatch('http', {
        api: 'deleteMenu',
        query: {
          id: id
        }
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '删除菜单成功'
        })
        // this.queryData(10) // 传入数字 重置列表不会更改当前页
        this.queryData() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'queryAppMenuList',
        params: {
          ...this.formState,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      console.log(res, '表格数据')

      this.data = res
      this.total = res.length
      this.$forceUpdate()
    },
    handleFinish () {
    },
    handleFinishFailed () {
      console.log()
    },
    resetForm () {
      this.formState = {
        name: '',
        code: undefined
      }
      this.queryData()
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(event, pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.deleteMenu(id)
    },
    cancel (values) {
      console.log(values)
    },
    // 弹出窗口
    showModal ({ editorAdd, id, parentId }) {
      this.visible = true
      setTimeout(() => {
        this.$refs.refModal.init({ editorAdd, id, parentId, appObj: this.appObj })
      }, 200)
    },
    handleOk (values) {
      this.queryData()
      this.visible = false
    }
  }
}
</script>

<style>
</style>
